<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="dealers"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            slot="header"
            class="flex items-center flex-grow"
            vs-type="flex-start"
            vs-justify="flex-start"
          >
            <vx-tooltip text="Add New Car Dealer" position="top" delay=".3s">
              <vs-button
                type="gradient"
                size="small"
                icon-pack="feather"
                icon="icon-user-plus"
                color="success"
                @click="addCarDealerHandler()"
              ></vs-button>
            </vx-tooltip>
          </div>
          <div class="flex">
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
            <div class="flex  flex-end">
              <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="dataTableParams.search" class="w-full"/>
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="displayAddress">Address</vs-th>
          <vs-th sort-key="licenseNumber">License Number</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="contactInformation.contactName">Contact Name</vs-th>
          <vs-th sort-key="contactInformation.email">Contact Email</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name"
              >{{ data[indextr].name }}
            </vs-td>
            <vs-td :data="data[indextr].displayAddress">
              {{ data[indextr].displayAddress }}
            </vs-td>
            <vs-td :data="data[indextr].licenseNumber">
              {{data[indextr].licenseNumber}}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr].contactInformation.contactName">
              {{ data[indextr].contactInformation.contactName }}
            </vs-td>
            <vs-td :data="data[indextr].contactInformation.email">
              {{ data[indextr].contactInformation.email }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
             
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                  <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="editDetailHandler(data[indextr]._id)"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: this.$store.getters.dealerPageSearch,
        sort: "createdAt",
        dir: "desc",
        page: this.$store.getters.dealerPage,
        limit: this.$store.getters.dealerPageLimit,
      },
      dealers: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedDealer: {},
      activePrompt: false,
    };
  },
  methods: {
    ...mapActions("carDealer", ["fetchCarDealers", "removeCarDealer", "createCarDealers"]),
    ...mapActions(["updateDealerPage", "initToFirstPage", "updateDealerPageLimit", "updateDealerPageSearch"]),
    moment(date) {
      return moment(date);
    },
    getDealersList() {
      let self = this;
      this.fetchCarDealers(self.dataTableParams).then((res) => {
        self.dealers = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDealersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getDealersList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDealersList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "AddCarDealer", params: { id: id } });
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "CarDealerDetails", params: { id: id } });
    },
    addCarDealerHandler() {
      this.$router.push({ name: "AddCarDealer" });
    },
    deleteDetailHandler(dealer, i) {
      this.selectedDealer = dealer;
      this.selectedIndex = i;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${dealer.name}"`,
        accept: this.confirmDeleteRecord,
        acceptText: "Delete",
      });
    },
    confirmDeleteRecord() {
      this.removeCarDealer(this.selectedDealer._id).then((res) => {
        this.$vs.notify({
          title: "Car Dealer Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.dealers.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateDealerPage(newVal);
        this.dataTableParams.page = newVal;
        this.getDealersList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateDealerPageLimit(newlimit);
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getDealersList();
      }
    },
    "dataTableParams.search": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = 1;
        this.updateDealerPageSearch(newVal)
        this.dataTableParams.search = newVal;
        this.getDealersList();
      }
    },
  },
  created() {
    this.initToFirstPage({pageType: "dealer"});
    this.getDealersList();
  },
};
</script>
